<div class="pilot-user-sign-up-form-container">
  <div class="_modal-row _heading">
    {{ vm.titleText }}
  </div>
  <div [innerHTML]="vm.promptText" class="_modal-row _content"></div>
  <div class="_modal-row _inputs">
    <mat-grid-list cols="2" rowHeight="68px" gutterSize="16px">
      <ng-container *ngFor="let item of pageCompServ.formArrangement">
        <mat-grid-tile [colspan]="item.cols" [rowspan]="item.rows">
          <ng-container *ngIf="pageCompServ.isSelect(item.type)">
            <app-form-select
              [selected]="pageCompServ.getValue(item.type)"
              [labelText]="pageCompServ.getLabelText(item.type)"
              [placeholderText]="pageCompServ.getPlaceHolder(item.type)"
              [options]="pageCompServ.getOptions(item.type)"
              (changeEvent)="pageCompServ.handleSelectChange($event, item.type)"
            ></app-form-select>
          </ng-container>
          <ng-container *ngIf="pageCompServ.isInput(item.type)">
            <app-form-input
              [control]="pageCompServ.getControl(item.type)"
              [labelText]="pageCompServ.getLabelText(item.type)"
              [placeholderText]="pageCompServ.getPlaceHolder(item.type)"
              [errorMessage]="pageCompServ.getErrorMessage(item.type)"
              [hint]="pageCompServ.getHint(item.type)"
              [mask]="pageCompServ.getMask(item.type)"
              [icon]="pageCompServ.getIcon(item.type)"
              [prefix]="pageCompServ.getPrefix(item.type)"
            >
            </app-form-input>
          </ng-container>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>
  </div>
  <div class="_modal-row _action-buttons">
    <app-button
      [loading]="pageCompServ.isPilotSignUpLoading$ | async"
      [borderRadius]="buttonsVm.btnBorderRadius"
      [width]="buttonsVm.btnWidth"
      [height]="buttonsVm.btnHeight"
      [cb]="pageCompServ.onSubmit"
      [disabled]="!(pageCompServ.canSubmit$ | async)"
      [buttonText]="vm.confirmButtonText"
      [buttonType]="buttonsVm.primaryBtnType"
    >
    </app-button>
    <app-button
      [borderRadius]="buttonsVm.btnBorderRadius"
      [width]="buttonsVm.btnWidth"
      [height]="buttonsVm.btnHeight"
      [cb]="onCancel"
      [disabled]="pageCompServ.isPilotSignUpLoading$ | async"
      [buttonText]="'Cancel'"
      [buttonType]="buttonsVm.secondaryBtnType"
    >
    </app-button>
  </div>
</div>
